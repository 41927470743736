import { createSlice } from "@reduxjs/toolkit";

interface IWorkingData {
  workingData: any[];
  cachedProcessIds: any[];
}

const initialState: IWorkingData = {
  workingData: [],
  cachedProcessIds: [],
};

export const adminWorkingDataSlice = createSlice({
  name: "adminWorkingData",
  initialState,
  reducers: {
    setWorkingData: (state, action) => {
      state.workingData = action.payload;
    },
    pushWorkingData: (state, action) => {
      state.workingData.push(action.payload);
    },
    deleteWorkingData: (state, action) => {
      state.workingData = [];
    },
    setCachedProcessIds: (state, action) => {
      state.cachedProcessIds = action.payload;
    },
    pushCachedProcessId: (state, action) => {
      state.cachedProcessIds.push(action.payload);
    },
    deleteCachedProcessIds: (state, action) => {
      state.cachedProcessIds = [];
    },
  }
});

export const { 
  setWorkingData, 
  pushWorkingData, 
  deleteWorkingData,
  setCachedProcessIds,
  pushCachedProcessId,
  deleteCachedProcessIds,
} = adminWorkingDataSlice.actions;

export default adminWorkingDataSlice.reducer;