import { Box } from "@mui/material";

interface Props {
  onClick?: () => void;
  style?: any;
}
export const IMachiningLine: React.FC<Props> = (props) => {
  return (
    <Box display="flex" {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.3495 14.0591H4.65054C3.18824 14.0591 2 15.2473 2 16.7096C2 18.1719 3.18824 19.3602 4.65054 19.3602H31.3495C32.8118 19.3602 34 18.1719 34 16.7096C34 15.2473 32.8118 14.0591 31.3495 14.0591ZM4.3819 17.4783C3.95762 17.4783 3.6132 17.1339 3.6132 16.7096C3.6132 16.2853 3.95762 15.9457 4.3819 15.9457C4.80617 15.9457 5.14587 16.2853 5.14587 16.7096C5.14587 17.1339 4.80144 17.4783 4.3819 17.4783ZM31.6188 17.4783C31.1945 17.4783 30.8501 17.1339 30.8501 16.7096C30.8501 16.2853 31.1945 15.9457 31.6188 15.9457C32.0431 15.9457 32.3827 16.2853 32.3827 16.7096C32.3827 17.1339 32.0431 17.4783 31.6188 17.4783Z"
          fill="#7B7B7B"
        />
        <path
          d="M31.6187 20.5227H4.38184V28.1854H31.6187V20.5227Z"
          fill="#7B7B7B"
        />
        <path
          d="M12.1506 8H5.35681V13.2984H12.1506V8ZM8.66576 9.13682H8.04998V8.23007H8.66576V9.13682ZM9.45747 9.13682H8.84169V8.23007H9.45747V9.13682Z"
          fill="#7B7B7B"
        />
        <path
          d="M21.3969 8H14.603V13.2984H21.3969V8ZM17.912 9.13682H17.2962V8.23007H17.912V9.13682ZM18.7037 9.13682H18.0879V8.23007H18.7037V9.13682Z"
          fill="#7B7B7B"
        />
        <path
          d="M30.6437 8H23.8499V13.2984H30.6437V8ZM27.1588 9.13682H26.543V8.23007H27.1588V9.13682ZM27.9505 9.13682H27.3347V8.23007H27.9505V9.13682Z"
          fill="#7B7B7B"
        />
      </svg>
    </Box>
  );
};
