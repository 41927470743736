import { Box } from "@mui/material";

export const IPrevious = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.839"
        height="29.839"
        viewBox="678 154 29.839 29.839"
      >
        <path
          d="M692.92 154c8.238 0 14.92 6.681 14.92 14.92 0 8.238-6.682 14.92-14.92 14.92-8.239 0-14.92-6.682-14.92-14.92 0-8.239 6.681-14.92 14.92-14.92Zm1.863 7.456a1.863 1.863 0 0 0-1.322.556l-2.794 2.793-2.788 2.788c-.173.173-.31.37-.404.596a1.85 1.85 0 0 0-.152.73 1.884 1.884 0 0 0 .556 1.327l2.788 2.79 2.794 2.794a1.885 1.885 0 0 0 1.322.552 1.871 1.871 0 0 0 1.868-1.866c0-.26-.052-.508-.15-.733a1.898 1.898 0 0 0-.404-.593l-2.134-2.134-2.136-2.137 2.136-2.134 2.134-2.14c.168-.166.31-.364.404-.59a1.856 1.856 0 0 0-.398-2.049 1.857 1.857 0 0 0-1.32-.55Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="パス 1338"
        />
      </svg>
    </Box>
  );
};
