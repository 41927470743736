import { Button, Form, Image, Select } from "antd";
import AdminLayout from "../adminLayout";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLogoutAction } from "store/sagas/adminAuthSaga";
import { AdminLogoutParams } from "types/auth";
import axios from "config/axios";
import constant from "constant/action";
import { useEffect, useState } from "react";
import { MachiningLineAttribute, MachiningLineSelectOption } from "types/machiningLine";
import { MachiningProcessAttribute, MachiningProcessSelectOption } from "types/machiningProcess";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const DeviceSettings = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSelectedLineId = () => {
    const selectedLineId = localStorage.getItem("selected_line");
    const selectedLineName = localStorage.getItem("selected_line_name");
    if (selectedLineId && selectedLineName) {
      return {
        value: Number(selectedLineId),
        label: selectedLineName,
      };
    }
    return null;
  };
  const [lineList, setLineList] = useState<MachiningLineSelectOption[]>([{ value: -1, label: "ラインを選択してください" }]);
  const [selectedLine, setSelectedLine] = useState<MachiningLineSelectOption | null>(() => getSelectedLineId());

  const getSelectedProcessId = () => {
    const selectedProcessId = localStorage.getItem("selected_process");
    const selectedProcessName = localStorage.getItem("selected_process_name");
    if (selectedProcessId && selectedProcessName) {
      return {
        value: Number(selectedProcessId),
        label: selectedProcessName
      };
    }
    return null;
  }
  const [processList, setProcessList] = useState<MachiningProcessSelectOption[]>([{ value: -1, label: "工程を選択してください" }]);
  const [selectedProcess, setSelectedProcess] = useState<MachiningProcessSelectOption | null>(() => getSelectedProcessId());

  const [isValidationMessageMachiningLine, setIsValidationMessageMachiningLine] = useState<boolean>(false);
  const [isValidationMessageMachiningProcess, setIsValidationMessageMachiningProcess] = useState<boolean>(false);

  const handleLogout = () => {
    const data: AdminLogoutParams = {
      logout_type: "primary"
    };
    dispatch(adminLogoutAction(data));
    localStorage.removeItem('selected_process');
    localStorage.removeItem('selected_process_name');
    localStorage.removeItem('cachedProcesses');
    // navigate("/admin/login");
  }

  useEffect(() => {
    const fetchMachiningLines = async () => {
      try {
        const url = constant.MACHINING_LINES;
        const response = await axios.get(url);
        if (response && response.data) {
          const lineOptions = response.data.data.map((item: MachiningLineAttribute) => {
            return { value: item.id, label: item.line_jpn };
          });
          setLineList((prev) => [...prev, ...lineOptions]);
        }
      } catch (error) {
        // Handle error
      }
    };
    fetchMachiningLines();
  }, []);
  
  useEffect(() => {
    const fetchMachiningProcesses = async () => {
      try {
        const url = constant.MACHINING_PROCESSES;
        const response = await axios.get(url);
        if (response && response.data) {
          const processOptions = response.data.data.map((item: MachiningProcessAttribute) => {
            return { value: item.id, label: item.process_jpn };
          });
          setProcessList((prev) => [...prev, ...processOptions]);
        }
      } catch (error) {
        // error
      }
    }
    fetchMachiningProcesses();
  }, [])

  const handleSelectLine = (value: any, option: MachiningLineSelectOption) => {
    if (value === -1) {
      setSelectedLine(null);
      return;
    }
    setSelectedLine(option);
  };

  const handleSelectProcess = (value: any, option: MachiningProcessSelectOption) => {
    if (value === -1) {
      setSelectedProcess(null);
      return;
    }
    setSelectedProcess(option);
  };

  const handleDecide = () => {
    if (selectedLine === null || selectedProcess === null) {
      setIsValidationMessageMachiningLine(selectedLine === null ? true : false);
      setIsValidationMessageMachiningProcess(selectedProcess === null ? true : false);
    } else {
      setIsValidationMessageMachiningLine(false);
      setIsValidationMessageMachiningProcess(false);
      localStorage.setItem("selected_line", String(selectedLine.value));
      localStorage.setItem("selected_line_name", selectedLine.label);
      localStorage.setItem("selected_process", String(selectedProcess.value));
      localStorage.setItem("selected_process_name", selectedProcess.label);
      navigate(`/admin/work?line_id=${selectedLine.value}&process_id=${selectedProcess.value}`);
    }
  };

  const handleCycleTimeSetting = () => {
    if (selectedProcess === null) {
      setIsValidationMessageMachiningProcess(true);
    } else {
      setIsValidationMessageMachiningProcess(false);
      localStorage.setItem("selected_process", String(selectedProcess.value));
      localStorage.setItem("selected_process_name", selectedProcess.label);
      navigate(`/admin/cycle-time-settings?process_id=${selectedProcess.value}`);
    }
  };

  const handleFullScreen = () => {
    navigate(`/admin/full-screen`);
  }

  return (
    <>
      <AdminLayout>
        <div className="setting-form">
          <Form {...layout} form={form} name="nest-messages">
            <p className="title-device">ライン選択</p>
            <Form.Item name="line">
              <div>
                <Select
                  // value={selectedProcess?.value}
                  defaultValue={selectedLine?.label || -1}
                  className="select-device"
                  options={lineList}
                  suffixIcon={
                    <Image
                      preview={false}
                      src="https://i.imgur.com/0mU6EI5.png"
                    />
                  }
                  onSelect={handleSelectLine}
                />
                {isValidationMessageMachiningLine && <p className="text-red-500 pt-1 pl-2">ラインを選択してください</p>}
              </div>
            </Form.Item>

            <p className="title-device">工程選択</p>
            <Form.Item name="device">
              <div>
                <Select
                  // value={selectedProcess?.value}
                  defaultValue={selectedProcess?.label || -1}
                  className="select-device"
                  options={processList}
                  suffixIcon={
                    <Image
                      preview={false}
                      src="https://i.imgur.com/0mU6EI5.png"
                    />
                  }
                  onSelect={handleSelectProcess}
                />
                {isValidationMessageMachiningProcess && <p className="text-red-500 pt-1 pl-2">工程を選択してください</p>}
              </div>              
            </Form.Item>

            <div className="flex items-center justify-between mt-5">
              <Button
                className="button-decide font-noto-sans text-[16px] not-italic font-bold"
                onClick={handleDecide}
              >
                作業者情報
              </Button>
              <Button
                className="button-decide font-noto-sans text-[16px] not-italic font-bold"
                onClick={handleCycleTimeSetting}
              >
                サイクルタイム設定
              </Button>
            </div>
            <hr className="my-10 h-0.5 bg-gray-200" />
            <div>
              <p className="title-device">全画面表示のデバイスとして利用</p>
              <div className="flex items-center justify-center mt-5">
                <Button
                  className="button-decide font-noto-sans text-[16px] not-italic font-bold"
                  onClick={handleFullScreen}
                >
                  全画面表示
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-center mt-10">
              <Button
                className="button-decide bt-return font-noto-sans text-[16px] not-italic font-bold"
                onClick={handleLogout}
              >
                戻る
              </Button>
            </div>
          </Form>
        </div>
      </AdminLayout>
    </>
  );
};
export default DeviceSettings;
