import { useState, useEffect } from "react";
import { IMobile } from "../../../icons/IMobile";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import styled from "styled-components";
import ChangePasswordModal from "components/ChangePasswordModal";
import LevelTag from "components/LevelTag";
import UnreadItem from "components/UnreadItem";

const ContentImage = styled.div`
  background-image: url("https://i.imgur.com/o7Q4JGg.png") !important;
  min-height: 707px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  @media only screen and (max-width: 1280px) {
    min-height: 600px;
  }
`;

const WorkerLayout = ({ children, ...props }: any) => {
  const [isOpenModalAccountName, setIsOpenModalAccountName] = useState(false);
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    let updatePasswordSuccessTimeout: NodeJS.Timeout;
    if (updatePasswordSuccess) {
      updatePasswordSuccessTimeout = setTimeout(() => {
        setUpdatePasswordSuccess(false);
      }, 5000);
    }
    return () => clearTimeout(updatePasswordSuccessTimeout);
  }, [updatePasswordSuccess]);

  useEffect(() => {
    let requiredErrorTimeout: NodeJS.Timeout;
    if (requiredError) {
      requiredErrorTimeout = setTimeout(() => {
        setRequiredError(false);
      }, 5000);
    }
    return () => clearTimeout(requiredErrorTimeout);
  }, [requiredError]);

  const handleBack = () => {
    localStorage.removeItem("selected_line");
    localStorage.removeItem("selected_line_name");
    localStorage.removeItem("selected_process");
    localStorage.removeItem("selected_process_name");
    localStorage.removeItem("cachedProcesses");
    navigate("/admin/device-settings");
  };

  return (
    <>
      <div className="header-admin">
        <div className="accountInfo" onClick={(e) => e.stopPropagation()}>
          <div className="accountName">
            <p className="label">作業者名</p>
            <p className="value">{props.currentUserRecord?.name}</p>
          </div>
          <div className="accountId">
            <p className="label">作業者コード</p>
            <p className="value">{props.currentUserRecord?.account_id}</p>
          </div>
          <LevelTag
            type="worker"
            level={+props.currentUserRecord?.level || 0}
            screen="working"
          />
        </div>
        <div className="rightSideWrapper">
          <UnreadItem amount={props.currentUserRecord?.unread_items || 0} />
          <div
            className="changePassword"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenModalAccountName(true);
            }}
          >
            <IMobile />
            <p>アプリ用パスワード設定</p>
          </div>
          <button onClick={handleBack} className="returnBtn">
            戻る
          </button>
        </div>
      </div>
      <ContentImage>
        <div className="childrenWrapper">{children}</div>
      </ContentImage>
      <ChangePasswordModal
        open={isOpenModalAccountName}
        setOpen={setIsOpenModalAccountName}
        accountName={props.currentUserRecord?.name}
        currentUserRecord={props.currentUserRecord}
      />
    </>
  );
};
export default WorkerLayout;
