import React from "react";
import "./style.scss";
interface LevelTagProps {
  type: "worker" | "process";
  screen: "working" | "skillCheck" | "notiEffect";
  level?: number;
  style?: React.CSSProperties;
}
const LabelTagEnum = Object.freeze({
  worker: "作業者レベル",
  process: "機種レベル"
});

const LevelTag: React.FC<LevelTagProps> = ({
  type,
  screen,
  level,
  style = {},
}) => {
  const renderedLevel = level && level < 10 ? `0${level}` : level;
  return (
    <div className={`levelTagWrapper ${type} ${screen}`} style={style}>
      <p className="label">
        {LabelTagEnum[type]}
      </p>
      {
        level !== undefined && (
          <p className="lvlValue">
            Lv.{renderedLevel || "00"}
          </p>
        )
      }
    </div>
  )
}

export default LevelTag;