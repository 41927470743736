import React, { useState, useEffect } from "react";
import "./style.scss";
import { UserAttribute } from "types/user";
import { UserCharacterAttribute } from "types/userCharacter";
import { UserCharacterItemAttribute } from "types/userCharacterItem";
import NewAvatarImage, { ICharacterFullImage } from "components/NewAvatarImage";

interface CharacterProps {
    user: UserAttribute;
}

const Character: React.FC<CharacterProps> = ({
    user
}) => {
    const [fullCharacterImage, setFullCharacterImage] = useState<ICharacterFullImage | null>(null);
    const [itemZIndex, setItemZIndex] = useState<{ hat: number, shoe: number, cloth: number }>({ hat: 2, shoe: 3, cloth: 4 })


    useEffect(() => {
        const mainCharacter = user.user_characters?.find((userCharacter: UserCharacterAttribute) => userCharacter.is_main === true);
        const bodyImage = mainCharacter?.character?.image_path || '';
        const hatItem = mainCharacter?.user_character_items.find((userCharacterItem: UserCharacterItemAttribute) => userCharacterItem?.user_items?.item?.item_type === "hat");
        const hatImage = hatItem?.user_items?.item?.asset?.image_path || undefined;
        const clothItem = mainCharacter?.user_character_items.find((userCharacterItem: UserCharacterItemAttribute) => userCharacterItem?.user_items?.item?.item_type === "cloth");
        const clothImage = clothItem?.user_items?.item?.asset?.image_path || undefined;
        const shoeItem = mainCharacter?.user_character_items.find((userCharacterItem: UserCharacterItemAttribute) => userCharacterItem?.user_items?.item?.item_type === "shoe");
        const shoeImage = shoeItem?.user_items?.item?.asset?.image_path || undefined;

        setFullCharacterImage({
            body: bodyImage,
            hat: hatImage,
            cloth: clothImage,
            shoe: shoeImage,
            characterId: mainCharacter?.character?.id || 0,
        });

        setItemZIndex({
            hat: hatItem?.user_items.item.z_index || 2,
            shoe: shoeItem?.user_items.item.z_index || 3,
            cloth: clothItem?.user_items.item.z_index || 4,
        });
    }, [user]);

    return (
        <>
            {fullCharacterImage !== null && (
                <NewAvatarImage 
                    fullImage={fullCharacterImage}
                    itemZIndex={itemZIndex}
                />
            )}
        </>
    );
};
export default Character;