import { Box } from "@mui/material";

export const ILevelUp = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="53.639"
        height="55.247"
        viewBox="7.9 347.5 53.639 55.247"
      >
        <g data-name="合体 1">
          <path
            d="M18.958 55.247v-2.27h15.689v2.27H18.958Zm22.453-.23v-1.27h8.767v1.27h-8.767Zm-37.95 0v-1.27h8.767v1.27H3.461Zm37.95-2.885v-1.269h8.767v1.27h-8.767Zm-37.95 0v-1.269h8.767v1.27H3.461Zm15.497-2.046v-2.27h15.69v2.27h-15.69Zm22.453-.838v-1.384h8.767v1.384h-8.767Zm-37.95 0v-1.384h8.767v1.384H3.461Zm37.95-2.999v-1.385h8.767v1.385h-8.767Zm-37.95 0v-1.384h8.767v1.384H3.461Zm15.497-1.324v-2.477h15.689v2.477H18.958Zm22.453-1.675V31.944h8.767V43.25h-8.767Zm-37.95 0V31.944h8.767V43.25H3.461Zm15.497-3.692v-2.477h15.689v2.477H18.958Zm0-5.367V14.038h-6.27L26.725 0l14.038 14.038h-6.116V34.19H18.958Zm18.992-2.247 7.845-7.844 7.844 7.844H37.95Zm-37.95 0L7.845 24.1l7.844 7.844H0Z"
            fillRule="evenodd"
            fill='url("#a")'
            transform="translate(8.4 348)"
          />
          <path
            d="M27.358 403.247v-2.27h15.689v2.27H27.358Zm22.453-.23v-1.27h8.767v1.27h-8.767Zm-37.95 0v-1.27h8.767v1.27h-8.767Zm37.95-2.885v-1.269h8.767v1.27h-8.767Zm-37.95 0v-1.269h8.767v1.27h-8.767Zm15.497-2.046v-2.27h15.69v2.27h-15.69Zm22.453-.838v-1.384h8.767v1.384h-8.767Zm-37.95 0v-1.384h8.767v1.384h-8.767Zm37.95-2.999h0v-1.385h8.767v1.385h-8.767Zm-37.95 0v-1.384h8.767v1.384h-8.767Zm15.497-1.324v-2.477h15.689v2.477H27.358Zm22.453-1.675v-11.306h8.767v11.306h-8.767Zm-37.95 0v-11.306h8.767v11.306h-8.767Zm15.497-3.692v-2.477h15.689v2.477H27.358Zm0-5.367v-20.153h-6.27L35.125 348l14.038 14.038h-6.116v20.153H27.358Zm18.992-2.247 7.845-7.844 7.844 7.844H46.35Zm-37.95 0 7.845-7.844 7.844 7.844H8.4Z"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="rgba(0,0,0,0 )"
            fill="transparent"
          />
        </g>
        <defs>
          <linearGradient x1=".5" y1=".605" x2=".5" y2="1.097" id="a">
            <stop stop-color="#ed751c" offset="0" />
            <stop stop-color="rgba(237,117,28,0 )" offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};
