import React, { useState } from "react";
import { Spin } from "antd";
import "./style.scss";

export interface ICharacterFullImage {
  body: string;
  hat?: string;
  shoe?: string;
  cloth?: string;
  characterId: number;
}

export interface ItemZIndex {
  hat: number;
  shoe: number;
  cloth: number;
}

interface NewAvatarImageProps {
  fullImage: ICharacterFullImage;
  itemZIndex: ItemZIndex;
  style?: React.CSSProperties;
}

interface ILoadState {
  body: boolean;
  hat?: boolean;
  cloth?: boolean;
  shoe?: boolean;
}

const NewAvatarImage: React.FC<NewAvatarImageProps> = ({
  fullImage,
  itemZIndex,
  style = {}
}) => {
  const [itemsLoaded, setItemsLoaded] = useState<ILoadState>({ 
    body: false,
    hat: fullImage.hat ? false : undefined,
    cloth: fullImage.cloth ? false : undefined,
    shoe: fullImage.shoe ? false : undefined,
  });
  const handleImageLoad = (img: "body" | "hat" | "cloth" | "shoe") => {
    setItemsLoaded(prevState => ({
      ...prevState,
      [img]: true,
    }));
  };
  const areAllImagesLoaded = Object.values(itemsLoaded).filter(item => typeof item === "boolean").every(item => item === true);
  return (
    <div className="newAvatarImageContainer" style={style}>
      <div className="imgContainer">
          {
            fullImage.body && (
              <img src={fullImage.body} 
                alt="body" 
                className="characterBody" 
                onLoad={() => handleImageLoad("body")} 
                style={{ visibility: areAllImagesLoaded ? "visible" : "hidden" }}
              />
            )
          }
          {
            fullImage.hat && (
              <img  
                src={fullImage.hat} 
                alt="hat" 
                className="hat" 
                onLoad={() => handleImageLoad("hat")}
                style={{ 
                  visibility: areAllImagesLoaded ? "visible" : "hidden",
                  zIndex: itemZIndex.hat
                }}
              />
            )
          }
          {
            fullImage.shoe && (
              <img 
                src={fullImage.shoe} 
                alt="shoe" 
                className="shoe"
                onLoad={() => handleImageLoad("shoe")}
                style={{
                  visibility: areAllImagesLoaded ? "visible" : "hidden",
                  zIndex: itemZIndex.shoe
                }}
              />
            )
          }
          {
            fullImage.cloth && (
              <img 
                src={fullImage.cloth} 
                alt="cloth"
                className="cloth" 
                onLoad={() => handleImageLoad("cloth")}
                style={{
                  visibility: areAllImagesLoaded ? "visible" : "hidden",
                  zIndex: itemZIndex.cloth
                }}
              />
            )
          }

          {
            !areAllImagesLoaded && (
              <div className="spinContainer">
                <Spin />
              </div>
            )
          }

      </div>
    </div>
  )
}

export default NewAvatarImage;