import { Box } from "@mui/material";

interface Props {
  onClick?: () => void;
  style?: any;
}
export const IEdit: React.FC<Props> = (props) => {
  return (
    <Box display="flex" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.918"
        height="14.919"
        viewBox="759.5 448.5 14.918 14.919"
      >
        <g data-name="グループ 2895">
          <path
            d="m771.814 448.774 2.332 2.332c.182.18.272.42.272.657 0 .241-.09.48-.272.66l-1.303 1.303-3.65-3.65 1.302-1.302a.934.934 0 0 1 1.319 0Zm-9.654 8.257 3.73 3.729 6.334-6.334-.603-.603-.66-.66-1.205-1.205-.66-.66-.602-.602-6.334 6.335Zm-2.632 5.766.52-1.431 1.506 1.505-1.43.522a.466.466 0 0 1-.595-.595h-.001Zm.871-2.4.982-2.698a.55.55 0 0 1 .037-.093l3.894 3.894c-.02.01-.04.018-.06.026l-2.731.993-2.122-2.121Z"
            fill="#7b7b7b"
            fillRule="evenodd"
            data-name="パス 1394"
          />
        </g>
      </svg>
    </Box>
  );
};
