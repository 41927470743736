import { Box } from "@mui/material";

export const IMobile = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.593"
        height="28.136"
        viewBox="-2076.507 -2045.393 16.593 28.136"
      >
        <path
          d="M-2074.343-2028.8v-12.264c0-.399.323-.722.721-.722h8.658c.398 0 .721.323.721.722v9.018c.18 0 .359.014.532.043.6.097 1.162.366 1.628.751h.004v-11.255a2.886 2.886 0 0 0-2.885-2.886h-8.658a2.886 2.886 0 0 0-2.885 2.886v15.214a3.76 3.76 0 0 1 2.3-1.109.703.703 0 0 1-.136-.398m13.228-.702c-.53-.086-1.143.122-1.563.482-.147-.597-.713-1.104-1.322-1.203-.506-.082-1.08.103-1.495.431-.188-.574-.799-1.056-1.391-1.153-.572-.093-1.352.212-1.686.632v-4.558c0-.631-.37-1.24-.972-1.43a1.448 1.448 0 0 0-1.913 1.369v11.543a1.443 1.443 0 0 1-1.443-1.443v-1.303a.5.5 0 0 0-.5-.5h-.436a1.95 1.95 0 0 0-1.95 1.95v3.56c0 .624.27 1.218.738 1.628l1.942 1.703c.395.346.902.537 1.427.537h7.982c.574 0 1.125-.228 1.531-.634l1.618-1.618c.406-.406.634-.957.634-1.53v-6.962c0-.72-.491-1.385-1.2-1.5"
          fill="#7b7b7b"
          fillRule="evenodd"
          data-name="パス 7939"
        />
      </svg>
    </Box>
  );
};
