import { Button, Col, Row, InputNumber, PaginationProps, Pagination } from "antd";
import AdminLayout from "../adminLayout";
import "./style.scss";
import clsx from "clsx";
import { IEdit } from "icons/IEdit";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
// import { useDispatch } from "react-redux";
// import { adminLogoutAction } from "store/sagas/adminAuthSaga";
// import { AdminLogoutParams } from "types/auth";
import constant from "constant/action";
import axios from "config/axios";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";

const CycleTimeSettings = () => {
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);
  // const dispatch = useDispatch();
  const handleLogoutAndReturn = () => {
    // const data: AdminLogoutParams = {
    //   logout_type: "secondary",
    // };
    // dispatch(adminLogoutAction(data));
    navigate("/admin/device-settings");
  };
  const machiningProcessId = localStorage.getItem("selected_process");
  const [settingTimesList, setSettingTimesList] = useState<any[]>([]);
  const [editId, setEditId] = useState<number | null>(null);
  const [editInputType, setEditInputType] = useState<"standard_time_second" | "cycle_time_normal_range" | null>(null);
  const [editValue, setEditValue] = useState<any>();
  const selectedProcessName = localStorage.getItem("selected_process_name");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>();

  const fetchSettingTimes = useCallback(async () => {
    if (!machiningProcessId) {
      return;
    }
    try {
      const url = constant.SETTING_TIMES;
      const response = await axios.get(url, {
        params: {
          machining_process_id: +machiningProcessId,
          page: page,
          per_page: perPage,
        },
      });
      if (response) {
        setSettingTimesList(response.data.json.data.map((item: any) => {
            return { ...item, isUpdated: false };
        }));
        if (!totalCount) {
          setTotalCount(response.data.json.meta.total_records);
        }
      }
    } catch (error) {
      // error
    }
  }, [machiningProcessId, page, perPage, totalCount]);

  useEffect(() => {
    fetchSettingTimes();
  }, [fetchSettingTimes]);

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    setPage(page);
  };
  const onChangePerPage: PaginationProps["onShowSizeChange"] = (current, size) => {
    setPerPage(size);
  };
  const handleUpdateSettingTimes = async () => {
    if (!machiningProcessId) {
      return;
    }
    try {
      const data = {
        machining_process_id: machiningProcessId,
        setting_times: settingTimesList
          .filter((item) => item.isUpdated)
          .map((item) => {
            return {
              id: item.id,
              standard_time_second: item.standard_time_second,
              cycle_time_normal_range: Number(item.cycle_time_normal_range),
            };
          }),
      };
      const url = constant.SETTING_TIMES;
      const response = await axios.put(url, data);
      if (response) {
        fetchSettingTimes();
        setIsCompleted(true);
      }
    } catch (error) {
      // error
    }
  };
  const enableEditMode = (
    targetId: number,
    inputType: "standard_time_second" | "cycle_time_normal_range"
  ) => {
    if (editValue !== undefined) {
      setEditValue(undefined);
    }
    setEditId(targetId);
    setEditInputType(inputType);
  };
  const hangleChangeEditValue = (value: number | null) => {
    setEditValue(value);
  };
  const handleCancelEdit = () => {
    setEditId(null);
    setEditInputType(null);
    setEditValue(undefined);
  };
  const handleUpdateNewValue = () => {
    if (
      !editId ||
      !editInputType ||
      editValue === undefined ||
      editValue === null
    ) {
      return;
    }
    setSettingTimesList((prev) => [
      ...prev.map((item) => {
        if (item.id === editId) {
          if (editInputType === "standard_time_second") {
            return {
              ...item,
              standard_time_second: editValue,
              isUpdated: true,
            };
          } else if (editInputType === "cycle_time_normal_range") {
            return {
              ...item,
              cycle_time_normal_range: editValue,
              isUpdated: true,
            };
          }
        }
        return item;
      }),
    ]);
    setEditId(null);
    setEditInputType(null);
    setEditValue(undefined);
  };
  useEffect(() => {
    let completeTimeout: NodeJS.Timeout;
    if (isCompleted) {
      completeTimeout = setTimeout(() => {
        setIsCompleted(false);
      }, 5000);
    }
    return () => clearTimeout(completeTimeout);
  }, [isCompleted]);
  return (
    <>
      <AdminLayout>
        <div className="cycle-time-settings-container min-w-[900px]">
          <div className="pt-[70px] pb-[35px]">
            <p className="text-base font-bold m-0">{selectedProcessName}</p>
            <p className="text-2xl font-bold m-0">サイクルタイム設定</p>
          </div>
          <Row gutter={[6, 24]}>
            <Col span={12}>
              <div className="bg-[#006ab2] title-text font-noto-sans text-[14px] not-italic font-bold">
                機種名
              </div>
            </Col>
            <Col span={6}>
              <div className="bg-[#006ab2] title-text font-noto-sans text-[14px] not-italic font-bold">
                標準サイクルタイム（秒）
              </div>
            </Col>
            <Col span={6}>
              <div className="bg-[#006ab2] title-text pl-[0px] font-noto-sans text-[14px] not-italic font-bold">
                サイクルタイム正常範囲（%）
              </div>
            </Col>
          </Row>
          {settingTimesList.map((item, index) => (
            <Row gutter={[6, 24]} key={item?.id}>
              <Col span={12}>
                <div
                  className={clsx("row-text", {
                    "bg-[#eeeeee]": Number(index + 1) % 2 !== 0,
                    "bg-[#dedede]": Number(index + 1) % 2 === 0,
                  })}
                >
                  {item?.product_name}
                </div>
              </Col>
              <Col span={6}>
                <div
                  className={clsx("row-text", {
                    "bg-[#eeeeee]": Number(index + 1) % 2 !== 0,
                    "bg-[#dedede]": Number(index + 1) % 2 === 0,
                  })}
                >
                  <div className="flex justify-between">
                    {editId === item?.id &&
                    editInputType === "standard_time_second" ? (
                      <>
                        <InputNumber
                          defaultValue={item?.standard_time_second}
                          value={editValue}
                          onChange={hangleChangeEditValue}
                          min={1}
                        />
                        <div>
                          <CheckCircleFilled
                            style={{ fontSize: "18px" }}
                            onClick={handleUpdateNewValue}
                          />
                          <CloseOutlined
                            style={{ fontSize: "18px", marginLeft: "10px" }}
                            onClick={handleCancelEdit}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <p>{item?.standard_time_second}</p>
                        <IEdit
                          onClick={() =>
                            enableEditMode(item.id, "standard_time_second")
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  className={clsx("row-text", {
                    "bg-[#eeeeee]": Number(index + 1) % 2 !== 0,
                    "bg-[#dedede]": Number(index + 1) % 2 === 0,
                  })}
                >
                  <div className="flex justify-between">
                    {editId === item?.id &&
                    editInputType === "cycle_time_normal_range" ? (
                      <>
                        <InputNumber
                          defaultValue={Number(item?.cycle_time_normal_range)}
                          value={editValue}
                          onChange={hangleChangeEditValue}
                          step={0.1}
                          min={1.0}
                        />
                        <div>
                          <CheckCircleFilled
                            style={{ fontSize: "18px" }}
                            onClick={handleUpdateNewValue}
                          />
                          <CloseOutlined
                            style={{ fontSize: "18px", marginLeft: "10px" }}
                            onClick={handleCancelEdit}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <p> {item?.cycle_time_normal_range}</p>
                        <IEdit
                          onClick={() =>
                            enableEditMode(item.id, "cycle_time_normal_range")
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          ))}

          <div className="mt-5 flex justify-center">
            <Pagination
              current={page}
              total={totalCount}
              onChange={handleChangePage}
              onShowSizeChange={onChangePerPage}
              pageSizeOptions={[5, 10]}
              defaultPageSize={10}
            />
          </div>

          {/* <Table className="myTable"  columns={columns} dataSource={data} pagination={false} /> */}
          <div
            className={clsx("flex justify-center gap-x-[30px] pt-[50px]", {
              "pb-[70px]": !isCompleted,
              "pb-[27px]": isCompleted,
            })}
          >
            <Button className="btn-return" onClick={handleLogoutAndReturn}>
              戻る
            </Button>
            <Button
              className="btn-return btn-keep"
              onClick={handleUpdateSettingTimes}
            >
              保存
            </Button>
          </div>
          {isCompleted && (
            <p className="flex justify-center font-noto-sans font-bold text-[14px] not-italic text-[#006ab2] pb-[47px]">
              設定を保存しました
            </p>
          )}
        </div>
      </AdminLayout>
    </>
  );
};
export default CycleTimeSettings;
