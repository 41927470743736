import { Button, Form, Input, Modal } from "antd";
import AdminLayout from "../adminLayout";
import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { IClose } from "../../../icons/IClose";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminLoginParams, AdminLogoutParams } from "types/auth";
import { useDispatch } from "react-redux";
import { adminLogoutAction } from "store/sagas/adminAuthSaga";
import axios from "config/axios";
import constant from "constant/action";
import { isEmptyObject } from "utils/helper";
import libAxios from "axios";
import { handleSaveLog } from "services/log";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const ManufacturingStart = () => {
  const [isOpenModalProcessReset, setIsOpenModalProcessReset] = useState(false);
  const [isOpenModalProcessComplete, setIsOpenModalProcessComplete] = useState(false);
  const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);
  const [typeButton, setTypeButton] = useState<"cycle" | "reset" | "">("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timer = useRef<any>(null);
  const interval = useRef<any>();
  const selectedLineName = localStorage.getItem("selected_line_name");
  const selectedProcessName = localStorage.getItem('selected_process_name');
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryLineId = searchParams.get("line_id");
  const machiningLineId = localStorage.getItem("selected_line") || queryLineId;
  const queryProcessId = searchParams.get("process_id");
  const machiningProcessId = localStorage.getItem('selected_process') || queryProcessId;
  const navigateToAdminWorkScreen = () => {
    if (machiningLineId === null || machiningLineId === undefined || machiningProcessId === null || machiningProcessId === undefined) {
      return;
    }
    navigate(`/admin/work?line_id=${machiningLineId}&process_id=${machiningProcessId}`);
  }
  const cachedLines = localStorage.getItem("cachedLines");
  const cachedProcesses = localStorage.getItem("cachedProcesses");

  const stopNavigateFunction = () => {
    clearTimeout(timer.current);
  }

  const getWorkingProcessData = async () => {
    if (machiningLineId === null || machiningLineId === undefined || machiningProcessId === null || machiningProcessId === undefined) {
      return;
    }
    try {
      const response = await axios.get(constant.ADMIN_WORKING_PROCESS, {
        params: {
          machining_line_id: +machiningLineId,
          machining_process_id: +machiningProcessId,
        },
      });
      if (response) {
        const data = response.data;
        if (isEmptyObject(data)) {
          return;
        }
        if (!cachedLines || !cachedProcesses) {
          handleSaveLog({
            page: "/admin/manufacturing-start",
            type: "SUCCESS",
            param_machining_process_id: +machiningProcessId,
            account_id: data.account_id,
            user_product_id: data.machining_processes_detail?.user_product_id,
            product_name: data.machining_processes_detail?.name,
            conditional: "no-cached",
          });

          navigateToAdminWorkScreen();
          return;
        } else {
          // const jsonCachedLines = JSON.parse(cachedLines);
          const jsonCachedProcesses = JSON.parse(cachedProcesses);
          const workingProcessData = data.machining_processes_detail;
          if (
            ((jsonCachedProcesses.user_product_id === workingProcessData.user_product_id)
          && (jsonCachedProcesses.total_product !== workingProcessData.total_product))
          || (jsonCachedProcesses.user_product_id !== workingProcessData.user_product_id)
          ) {
            handleSaveLog({
              page: "/admin/manufacturing-start",
              type: "SUCCESS",
              param_machining_process_id: +machiningProcessId,
              account_id: data.account_id,
              user_product_id: data.machining_processes_detail?.user_product_id,
              product_name: data.machining_processes_detail?.name,
              conditional: "cached",
            });

            navigateToAdminWorkScreen();
          }
        }
      }
    } catch (error) {
      if (libAxios.isAxiosError(error)) {
        const errorData = error.response?.data?.errors;
        handleSaveLog({
          page: "/admin/manufacturing-start",
          type: "ERROR",
          param_machining_process_id: +machiningProcessId,
          ...errorData
        });
      }
    }
  }

  useEffect(() => {
    if (machiningLineId === null || machiningLineId === undefined || machiningProcessId === null || machiningProcessId === undefined) {
      const data: AdminLogoutParams = {
        logout_type: "secondary",
      };
      dispatch(adminLogoutAction(data));
    }
  });

  useEffect(() => {
    interval.current = setInterval(() => {
      getWorkingProcessData();
    }, 30 * 1000);
    return () => {
      clearInterval(interval.current);
    }
  });

  const handleSecondaryLogin = async (submitValue: AdminLoginParams) => {
    if (!submitValue.account_id || !submitValue.factory_code || !submitValue.password) {
      setRequiredError(true);
      return;
    }

    try {
      const data: AdminLoginParams = {
        account_id: submitValue.account_id,
        factory_code: submitValue.factory_code,
        password: submitValue.password,
      };
      const url = constant.ADMIN_LOGIN;
      const response = await axios.post(url, data);
      if (response && response.data) {
        localStorage.setItem("secondary_token", response.data?.access_token);
        if (typeButton === "cycle") {
          navigate("/admin/cycle-time-settings");
        }
        if (typeButton === "reset") {
          setIsOpenModalProcessReset(true);
        }
        setIsOpenModalLogin(false);
      }
    } catch (error: any) {
      if (error && error.response && error.response.status === 401) {
        setLoginError(true);
      } 
    } finally {
      form.resetFields();
    }
  }

  const handleLogoutAndCancel = () => {
    const data: AdminLogoutParams = {
      logout_type: "secondary",
    };
    dispatch(adminLogoutAction(data));
    setTypeButton("");
    setIsOpenModalProcessReset(false);
  }

  const handleConfirmReset = () => {
    setIsOpenModalProcessComplete(true);
    setIsOpenModalProcessReset(false);
  }

  const handleReturnAfterReset = () => {
    const secondaryData: AdminLogoutParams = {
      logout_type: "secondary",
    };
    dispatch(adminLogoutAction(secondaryData));
    const primaryData: AdminLogoutParams = {
      logout_type: "primary",
    };
    dispatch(adminLogoutAction(primaryData));
    localStorage.removeItem("selected_line");
    localStorage.removeItem("selected_line_name");
    localStorage.removeItem("cachedLines");
    localStorage.removeItem('selected_process');
    localStorage.removeItem('selected_process_name');
    localStorage.removeItem('cachedProcesses');
    // navigate("/admin/login");
  }

  useEffect(() => {
    let requiredErrorTimeout: NodeJS.Timeout;
    if (requiredError) {
      requiredErrorTimeout = setTimeout(() => {
        setRequiredError(false);
      }, 5000);
    }
    return () => clearTimeout(requiredErrorTimeout);
  }, [requiredError]);

  useEffect(() => {
    let loginErrorTimeout: NodeJS.Timeout;
    if (loginError) {
      loginErrorTimeout = setTimeout(() => {
        setLoginError(false);
      }, 5000);
    }
    return () => clearTimeout(loginErrorTimeout);
  }, [loginError]);

  return (
    <>
      <AdminLayout>
        <div className="manufacturing-start-container">
          <p className="text-manufacture pb-4">{selectedLineName}</p>
          <p className="text-manufacture">{selectedProcessName}</p>
          <p className="title-manufacture pt-[21px]">製造待機中</p>
          <div className="flex flex-col items-center gap-y-[40px] pt-[100px]">
            <Button
              className="button-cycle-time font-noto-sans text-[16px] not-italic font-bold"
              onClick={() => {
                setIsOpenModalLogin(true);
                setTypeButton("cycle");
                stopNavigateFunction();
              }}
            >
              サイクルタイム設定
            </Button>
            <Button
              className="button-cycle-time bt-process-reset font-noto-sans text-[16px] not-italic font-bold"
              onClick={() => {
                setIsOpenModalLogin(true);
                setTypeButton("reset");
                stopNavigateFunction();
              }}
            >
              リセット
            </Button>
          </div>
        </div>
      </AdminLayout>
      <Modal
        open={isOpenModalProcessReset}
        footer={null}
        closeIcon={null}
        className="modal-process-reset"
      >
        <div
          className="flex justify-end cursor-pointer"
          onClick={handleLogoutAndCancel}
        >
          <IClose />
        </div>
        <div className="flex flex-col items-center py-[50px]">
          <p className="title-process-reset ">本当にリセットしますか？</p>
          <Button
            className="button-cycle-time mt-[27px] font-noto-sans text-[16px] not-italic font-bold"
            onClick={handleConfirmReset}
          >
            リセット
          </Button>
        </div>
      </Modal>
      <Modal
        open={isOpenModalProcessComplete}
        footer={null}
        closeIcon={null}
        className="modal-process-complete"
      >
        <div
          className="flex justify-end cursor-pointer"
          onClick={handleReturnAfterReset}
        >
          <IClose />
        </div>
        <div className="flex flex-col items-center py-[50px]">
          <p className="title-process-complete ">ラインと工程をリセットしました</p>
          <Button
            className="button-cycle-time mt-[27px] font-noto-sans text-[16px] not-italic font-bold"
            onClick={handleReturnAfterReset}
          >
            戻る
          </Button>
        </div>
      </Modal>

      <Modal
        open={isOpenModalLogin}
        footer={null}
        closeIcon={null}
        className="modal-login"
      >
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => {
            setIsOpenModalLogin(false);
            setTypeButton("");
            form.resetFields();
          }}
        >
          <IClose />
        </div>
        <div className="pt-[24px] pb-[39px] flex flex-col items-center">
          <Form {...layout} form={form} name="nest-messages" onFinish={(values) => {
            handleSecondaryLogin(values);
          }}>
            <p className="title-login">工場ID</p>
            <Form.Item name="factory_code">
              <Input className="input-login" />
            </Form.Item>
            <p className="title-login">管理者ID</p>
            <Form.Item name="account_id">
              <Input className="input-login" />
            </Form.Item>
            <p className="title-login">パスワード</p>
            <Form.Item name="password">
              <Input type="password" className="input-login" />
            </Form.Item>
            <div className="flex justify-center">
              <Button
                className="bt-login font-noto-sans text-[16px] not-italic font-bold"
                htmlType="submit"
              >
                ログイン
              </Button>
            </div>
          </Form>
          <div className="font-noto-sans font-bold mt-5 text-center text-[16px] text-red-600">
            {loginError ? '情報は正しくありません。再入力をしてください。' 
            : requiredError ? 'このフィールドは入力必須です'
            : ''}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ManufacturingStart;
