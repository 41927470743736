import { Box } from "@mui/material";

interface Props {
  onClick?: () => void;
  style?: any;
}
export const IWork: React.FC<Props> = (props) => {
  return (
    <Box display="flex" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.576"
        height="25.576"
        viewBox="-2317.147 -2408.147 25.576 25.576"
      >
        <g data-name="グループ 2592">
          <path
            d="M-2302.421-2397.685a.775.775 0 0 0-.775-.775h-3.1a.775.775 0 0 0-.775.775v10.85h4.65v-10.85Zm6.588 1.55a.775.775 0 0 0-.775-.775h-3.1a.775.775 0 0 0-.776.775v9.3h4.65v-9.3Zm3.1 11.239h-23.251a1.163 1.163 0 0 0 0 2.325h23.25a1.163 1.163 0 0 0 0-2.325m-16.275-8.914a.775.775 0 0 0-.775-.775h-3.1a.775.775 0 0 0-.775.775v6.976h4.65v-6.976Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="パス 7869"
          />
          <path
            d="m-2294.71-2408.14-3.484.498a.688.688 0 0 0-.39 1.167l.806.805-2.81 2.81-4.619-3.501a1.164 1.164 0 0 0-1.44.028l-7.363 6.052a1.163 1.163 0 1 0 1.476 1.797l6.654-5.47 4.694 3.56c.462.35 1.113.307 1.524-.104l3.528-3.528.537.538c.401.4 1.087.172 1.168-.39l.497-3.484a.688.688 0 0 0-.778-.778"
            fill="#fff"
            fillRule="evenodd"
            data-name="パス 7870"
          />
        </g>
      </svg>
    </Box>
  );
};
