const actions = {
  ADMIN_LOGIN: "/api/v1/login",
  ADMIN_LOGOUT: "/api/v1/logout",
  CHANGE_PASSWORD: "api/v1/change_password",
  MACHINING_LINES: "api/v1/machining_lines",
  MACHINING_PROCESSES: "api/v1/machining_processes",
  MACHINING_PRODUCTS: "api/v1/machining_products",
  ADMIN_USERS: "/api/v1/admin/users",
  ADMIN_USERS_WORKING_PROCESS: "/api/v1/admin/users/working_process",
  SETTING_TIMES: "api/v1/setting_times",
  ADMIN_USERS_PRODUCT: "/api/v1/admin/user_products",
  ADMIN_WORKING_STATUS: "/api/v1/admin/working_status",
  ADMIN_WORKING_PROCESS: "/api/v1/admin/working_process",
  ADMIN_USER_LEVEL: "/api/v1/admin/user_level",
  ADMIN_LOGS: "/api/v1/admin/logs",
};
export default actions;
