import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Login from "./pages/login";
import DeviceSettings from "./pages/admin/deviceSettings";
import FullScreen from "./pages/admin/fullScreen";
import ManufacturingStart from "./pages/admin/manufacturingStart";
import CycleTimeSettings from "./pages/admin/cycletimeSettings";
import SkillCheck from "./pages/admin/skillCheck";
import Work from "./pages/admin/working";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

const queryClient = new QueryClient();

interface PrivateRouteProps {
  isLogined: boolean;
  children: JSX.Element;
}

function App() {
  const [isLoading] = useState(false);
  const { isAdminLoggedIn } = useSelector((state: RootState) => state.adminAuth);

  const PrivateRoute = ({ isLogined, children }: PrivateRouteProps) => {
    if (!isLogined) {
      window.location.href = "/admin/login";
    }

    return children;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <Routes>
            <Route path="/admin/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/admin/device-settings" element={
              <PrivateRoute isLogined={isAdminLoggedIn}>
                <DeviceSettings />
              </PrivateRoute>
            } />
            <Route path="/admin/full-screen" element={
              <PrivateRoute isLogined={isAdminLoggedIn}>
                <FullScreen />
              </PrivateRoute>
            } />
            <Route path="/admin/manufacturing-start" element={
              <PrivateRoute isLogined={isAdminLoggedIn}>
                <ManufacturingStart />
              </PrivateRoute>
            } />
            <Route path="/admin/cycle-time-settings" element={
              <PrivateRoute isLogined={isAdminLoggedIn}>
                <CycleTimeSettings />
              </PrivateRoute>
            } />
            <Route path="/admin/skill-check" element={
              <PrivateRoute isLogined={isAdminLoggedIn}>
                <SkillCheck />
              </PrivateRoute>
            } />
            <Route path="/admin/work" element={
              <PrivateRoute isLogined={isAdminLoggedIn}>
                <Work />
              </PrivateRoute>
            } />
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
