import { useEffect, useState, useCallback } from "react";
import { Form, Input, Select, Image, Progress, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import FullScreenLayout from "../fullScreenLayout";
import constant from "constant/action";
import axios from "config/axios";
import "./style.scss";
import { UserAttribute } from "types/user";
import { MachiningLineAttribute, MachiningLineSelectOption } from "types/machiningLine";
import { MachiningProcessAttribute, MachiningProcessSelectOption } from "types/machiningProcess";
import { ProductAttribute, ProductSelectOption } from "types/product";
import { IMachiningLine } from "../../../icons/IMachiningLine";
import { IDocument } from "../../../icons/IDocument";
import { IWork } from "../../../icons/IWork";
import { ITime } from "../../../icons/ITime";
import Character from "components/fullScreen/Character";
import CycleTimeChart from "components/fullScreen/CycleTimeChart";

const FullScreen = () => {
    interface Paginate {
        current_page: number;
        per_page: number;
        total_count: number;
        total_pages: number;
    }
    const [paginate, setPaginate] = useState<Paginate>({ current_page: 1, per_page: 8, total_count: 0, total_pages: 0 });
    const [page, setPage] = useState(1);
    const [userList, setUserList] = useState<UserAttribute[]>([]);
    const [lineList, setLineList] = useState<MachiningLineSelectOption[]>([{ value: 0, label: "なし" }]);
    const [processList, setProcessList] = useState<MachiningProcessSelectOption[]>([{ value: 0, label: "なし" }]);
    const [productList, setProductList] = useState<ProductSelectOption[]>([{ value: 0, label: "なし" }]);
    const [display, setDisplay] = useState(0);
    const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>(new Date());
    const [form] = Form.useForm();

    const fetch = useCallback(async () => {
        const url = constant.ADMIN_USERS_WORKING_PROCESS;
        const fieldsValue = form.getFieldsValue();
        const params = {
            page: page,
            per_page: 8,
            keyword: fieldsValue.keyword,
            machining_line_id: fieldsValue.line,
            machining_process_id: fieldsValue.process,
            product_id: fieldsValue.product,
        };
        await axios.get(url, { params }).then((response) => {
            setUserList(response.data.data);
            setPaginate(response.data.meta);
            setLastUpdatedAt(new Date());
        });
    }, [form, page]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch();
        }, 10 * 1000);
        return () => clearInterval(interval);
    }, [fetch]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplay((prevContent) => (prevContent + 1) % 2);
        }, 5 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchMachiningLines = async () => {
            try {
                const url = constant.MACHINING_LINES;
                const response = await axios.get(url);
                if (response && response.data) {
                const lineOptions = response.data.data.map((item: MachiningLineAttribute) => {
                    return { value: item.id, label: item.line_jpn };
                });
                setLineList((prev) => [...prev, ...lineOptions]);
                }
            } catch (error) {
                // Handle error
            }
        };
        fetchMachiningLines();
    }, []);

    useEffect(() => {
    const fetchMachiningProcesses = async () => {
            try {
                const url = constant.MACHINING_PROCESSES;
                const response = await axios.get(url);
                if (response && response.data) {
                const processOptions = response.data.data.map((item: MachiningProcessAttribute) => {
                    return { value: item.id, label: item.process_jpn };
                });
                setProcessList((prev) => [...prev, ...processOptions]);
                }
            } catch (error) {
                // error
            }
        }
        fetchMachiningProcesses();
    }, [])

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const url = constant.MACHINING_PRODUCTS;
                const response = await axios.get(url);
                if (response && response.data) {
                const productOptions = response.data.data.map((item: ProductAttribute) => {
                    return { value: item.id, label: item.katamei };
                });
                setProductList((prev) => [...prev, ...productOptions]);
                }
            } catch (error) {
                // error
            }
        }
        fetchProducts();
    }, [])

    const handleInputKeyword = () => {
        setPage(1);
        fetch();
    };

    const handleSelectLine = () => {
        setPage(1);
        fetch();
    };

    const handleSelectProcess = () => {
        setPage(1);
        fetch();
    };

    const handleSelectProduct = () => {
        setPage(1);
        fetch();
    };

    const changePage = (page: number) => {
        setPage(page);
    };

    return (
        <>
            <FullScreenLayout>
                <div className="full-screen-container">
                    <Form form={form} className="filter grid grid-cols-5 gap-4 pt-5">
                        <div className="col-span-2">
                            <div className="flex items-center">
                                <label htmlFor="" className="w-24 mb-6">絞り込み</label>
                                <Form.Item name="keyword" className="w-full relative">
                                    <Input className="input-text" placeholder="ユーザー検索" suffix={<SearchOutlined />} onChange={handleInputKeyword} />
                                </Form.Item>
                            </div>
                        </div> 
                        <Form.Item name="line" className="col-span-1">
                            <Select
                                placeholder="ライン"
                                className="select"
                                options={lineList}
                                suffixIcon={
                                <Image
                                    preview={false}
                                    src="https://i.imgur.com/0mU6EI5.png"
                                />
                                }
                                onSelect={handleSelectLine}
                            />
                        </Form.Item>
                        <Form.Item name="process" className="col-span-1">
                            <Select
                                placeholder="工程"
                                className="select"
                                options={processList}
                                suffixIcon={
                                <Image
                                    preview={false}
                                    src="https://i.imgur.com/0mU6EI5.png"
                                />
                                }
                                onSelect={handleSelectProcess}
                            />
                        </Form.Item>
                        <Form.Item name="product" className="col-span-1">
                            <Select
                                placeholder="機種"
                                className="select"
                                options={productList}
                                suffixIcon={
                                <Image
                                    preview={false}
                                    src="https://i.imgur.com/0mU6EI5.png"
                                />
                                }
                                onSelect={handleSelectProduct}
                            />
                        </Form.Item>
                    </Form>
                    <div className="grid grid-cols-4 gap-7 auto-rows-min">
                        {userList.map((user, index) => (
                            <div className="col-span-1" key={user.id}>
                                <div className="working">
                                    { display === 0 ? (
                                        <div className="leftSection">
                                            <div className="processNameLevel">
                                                <div>
                                                    <div className="inner_wrapper">
                                                        <IMachiningLine style={{ width: 16 }} />
                                                        <p className="process_Text">
                                                            {user.last_worker_record?.machining_line?.line_jpn || ''}
                                                        </p>
                                                    </div>
                                                    <div className="inner_wrapper ml-1">
                                                        <IDocument style={{ width: 8 }} />
                                                        <p className="process_Text ml-1">
                                                        {user.last_worker_record?.machining_process?.process_jpn || ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="inner_wrapper">
                                                    <p className="process_Name">
                                                        {user.machining_processes_detail?.name || ''}
                                                    </p>
                                                    <div className="process_Level">
                                                        <p className="label">
                                                            機種レベル
                                                        </p>
                                                        {
                                                            user.machining_processes_detail?.level && (
                                                            <p className="lvlValue">
                                                                Lv.{user.machining_processes_detail.level || "00"}
                                                            </p>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="boostingImage">
                                                <div className="boostingWrapper">
                                                    <Progress
                                                        strokeColor="#ffa0a0"
                                                        steps={10}
                                                        className="vertical-progress"
                                                        percent={user.machining_processes_detail?.percent_to_boost_level}
                                                        type="line"
                                                        showInfo={false}
                                                        size={[7, 10]}
                                                    />
                                                </div>
                                                <Character user={user}  />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="rightSection">
                                            <div className="upperData">
                                                <div className="total-number-work">
                                                    <div className="totalNumberWork__innerWrapper">
                                                        <div className="legend">
                                                            <IWork style={{ width: 12 }} />
                                                            <p>
                                                                総作業台数
                                                            </p>
                                                        </div>
                                                        <p className="data">
                                                            {user.machining_processes_detail?.total_product}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="total-number-work total-number-work-sub">
                                                    <div className="totalNumberWork__innerWrapper">
                                                        <div className="legend">
                                                            <IWork style={{ width: 12 }} />
                                                            <p>
                                                                今日の作業台数
                                                            </p>
                                                        </div>
                                                        <p className="data">
                                                            {user.machining_processes_detail?.total_product_in_day}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="total-number-time">
                                                    <div className="totalNumberTime__innerWrapper">
                                                        <div className="legend">
                                                            <ITime style={{ width: 12 }} />
                                                            <p>
                                                                総作業時間
                                                            </p>
                                                        </div>
                                                        <p className="data">
                                                            {((user.machining_processes_detail?.total_cumulative_time || 0) / 3600).toFixed(2)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="total-number-time total-number-time-sub">
                                                    <div className="totalNumberTime__innerWrapper">
                                                        <div className="legend">
                                                            <ITime style={{ width: 12 }} />
                                                            <p>
                                                                今日の作業時間
                                                            </p>
                                                        </div>
                                                        <p className="data">
                                                            {((user.machining_processes_detail?.total_cycle_time_in_day || 0) / 3600).toFixed(2)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cycleTimeChartWrapper">
                                                <CycleTimeChart 
                                                    currentCycleTime={user.machining_processes_detail?.current_cycle_time || 0}
                                                    standardCycleTime={user.machining_processes_detail?.standard_cycle_time || 0}
                                                    cycleTimeNormalRange={user.machining_processes_detail?.cycle_time_normal_range || 0}
                                                    mileStoneHeight="16px"
                                                    barHeight="12px"
                                                    fontSize="7px"
                                                    legendStyleType="work"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="userName">
                                        {user.name}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex items-center justify-center pt-5">
                        <Pagination current={paginate.current_page} pageSize={paginate.per_page} total={paginate.total_count} showLessItems={true} onChange={changePage} />
                    </div>
                    <div className="flex items-center justify-end py-2">
                        <p className="last-updated-at">{lastUpdatedAt.toLocaleString()}</p>
                    </div>
                </div>
            </FullScreenLayout>
        </>
    );
};
export default FullScreen;
