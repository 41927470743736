
import { useNavigate } from "react-router-dom";
import "./style.scss";
import styled from "styled-components";
import { ReactNode } from "react";

const ContentImage = styled.div`
  background-image: url("https://i.imgur.com/o7Q4JGg.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 707px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1280px;
  margin: auto;
  @media only screen and (max-width: 1280px) {
    min-height: 800px;
  }
  @media only screen and (max-width: 1150px) {
    min-height: 0;
  }
`;
interface Props {
  children?: ReactNode;
  // any props that come into the component
}
const FullScreenLayout = ({ children, ...props }: Props) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate('/admin/device-settings');
  };

  return (
    <>
      <div className="fullScreenLayoutContainer">
        <div className="header-full-screen">
          <p className="header-logo">F-Factory</p>
          <button onClick={handleLogout} className="returnBtn">
            設定リセット
          </button>
        </div>
        <ContentImage>
          <div className="childrenWrapper">{children}</div>
        </ContentImage>
      </div>
    </>
  );
};
export default FullScreenLayout;
